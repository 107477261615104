import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { ethers } from "ethers";
import DataContext from "../../context/context";
import Header from "../../layout/header/header";
import Sidebar from "../sidebar/sidebar";
import Footer from "../footer/footer";
import logo from "../../../assest/img/logo.png"


function Stake() {
    const [flexibleStakeInfo, setFlexibleStakeInfo] = useState(0);
    const [lockedStakeInfoInMonth, setLockedStakeInfoInMonth] = useState(0);
    const [lockedStakeInfoInThreeMonth, setLockedStakeInfoInThreeMonth] = useState(0);

    const [lockedStakeInfoIndexRangeOneMonth, setLockedStakeInfoIndexRangeOneMonth] = useState(0);
    const [lockedStakeInfoIndexRangeThreeMonth, setLockedStakeInfoIndexRangeThreeMonth] = useState(0);

    const [amountStake, setAmountStake] = useState(0);
    const [amountUnStake, setAmountUnStake] = useState(0);
    const [rewardFlexible, setRewardFlexible] = useState(0);
    const web3 = new Web3(Web3.givenProvider);
    const [amountAllowance, setAmountAllowance] = useState(0);
    const [accountAddress, setAccountAddress] = useState('');
    const [loading, setLoading] = useState(false);
    const [textValidate, setTextValidate] = useState('');
    const [stakeValue, setStakeValue] = useState('');
    const [rewardRateFlex, setRewardRateFlex] = useState(0);
    const [rewardRateOneMonth, setRewardRateOneMonth] = useState(0);
    const [rewardRateThreeMonth, setRewardRateThreeMonth] = useState(0);
    const [rewardFlexibleAmount, setRewardFlexibleAmount] = useState(0);
    const [rewardOneMonthAmount, setRewardOneMonthAmount] = useState(0);
    const [rewardThreeMonthAmount, setRewardThreeMonthAmount] = useState(0);
    // @ts-ignore
    const [bankTokenABI, setBankTokenABI] = useState(JSON.parse(process.env.REACT_APP_BANK_TOKEN_ABI));
    // @ts-ignore
    const [phoTokenABI, setPhoTokenABI] = useState(JSON.parse(process.env.REACT_APP_PHO_TOKEN_ABI));

    // @ts-ignore
    useEffect(async () => {
        // @ts-ignore
        Web3.givenProvider.on("accountsChanged", async (accounts: string[]) => {
            // @ts-ignore
            if (accounts[0]) {
                const address = accounts[0].toLowerCase();
                setAccountAddress(address);
                await getAmountAllowance(address);
                // @ts-ignore
                // await getRewardFlexible(address);
                // @ts-ignore
                await getLockedStakeInfoIndexRange(address);
                await getFlexibleStakeInfo(address);
            } else {
                setAccountAddress('');
            }
        });
    }, [])

    // @ts-ignore
    useEffect(async () => {
        // @ts-ignore
        Web3.givenProvider.on("chainChanged", async (chainId: number) => {
            /* ChainID: 0x61, 97 in decimal (Testnet) */
            /* ChainID: 0x38, 56 in decimal (Mainnet) */
            if (chainId.toString() !== "0x38") {
                setAccountAddress('');
                setAmountStake(0);
                setAmountUnStake(0);
                // @ts-ignore
                setStakeValue(value);
                setRewardRateFlex(0);
            } else {
                const accountAddress = await web3.eth.getAccounts();
                if (accountAddress[0]) {
                    const address = accountAddress[0].toLowerCase();
                    setAccountAddress(address);
                    await getAmountAllowance(address);
                    // @ts-ignore
                    await getRewardRate(address);
                    // @ts-ignore
                    await getLockedStakeInfoIndexRange(address);
                    await getFlexibleStakeInfo(address);

                }
            }
        });
    }, [])

    // @ts-ignore
    useEffect(async () => {
        if (web3) {
            const accountAddress = await web3.eth.getAccounts();
            if (accountAddress[0]) {
                const address = accountAddress[0].toLowerCase();
                setAccountAddress(address)
                await getAmountAllowance(address);
                // @ts-ignore
                await getRewardRate(address);
                // @ts-ignore
                await getLockedStakeInfoIndexRange(address);
                await getFlexibleStakeInfo(address);
            }
        }
    }, [])


    // @ts-ignore
    useEffect(async () => {
        if (web3) {
            const accountAddress = await web3.eth.getAccounts();
            if (accountAddress[0]) {
                const address = accountAddress[0].toLowerCase();
                // @ts-ignore
                await getLockedStakeInfoInOneMonth(address);
            }
        }

    }, [lockedStakeInfoIndexRangeOneMonth])

    // @ts-ignore
    useEffect(async () => {
        if (web3) {
            const accountAddress = await web3.eth.getAccounts();
            if (accountAddress[0]) {
                const address = accountAddress[0].toLowerCase();
                // @ts-ignore
                await getLockedStakeInfoInThreeMonth(address);
            }
        }

    }, [lockedStakeInfoIndexRangeThreeMonth])

    /* get total amount staking of user */
    // @ts-ignore
    const getFlexibleStakeInfo = async (address: string) => {
        let totalReward = 0;
        if (web3) {
            const contract = new web3.eth.Contract(bankTokenABI, process.env.REACT_APP_BANK_ADDRESS);
            await contract.methods.getFlexibleStakeInfo(address).call((err: any, res: any) => {
                if (err) {
                    console.log("Get amount staking fail.", err);
                    return;
                }
                if (res) {
                    setFlexibleStakeInfo(Number(ethers.utils.formatUnits(res.amount, 8)))
                    totalReward += Number(ethers.utils.formatUnits(res.reward, 8));
                }
                console.log(Number(ethers.utils.formatUnits(res.amount, 8)), totalReward);
                setRewardFlexibleAmount(totalReward)
            })
        }
    }

    // @ts-ignore
    const getLockedStakeInfoInOneMonth = async (address: string, index: number, stakeValue: number) => {
        if (web3) {
            const contract = new web3.eth.Contract(bankTokenABI, process.env.REACT_APP_BANK_ADDRESS);
            let totalAmount = 0;
            let totalReward = 0;
            for (let i = 0; i < lockedStakeInfoIndexRangeOneMonth; i++) {
                await contract.methods.getLockedStakeInfo(address, i, 0).call((err: any, res: any) => {
                    if (err) {
                        console.log("Get amount staking fail.", err);
                        return;
                    }
                    if (res) {
                        totalAmount += Number(ethers.utils.formatUnits(res.amount, 8));
                        totalReward += Number(ethers.utils.formatUnits(res.reward, 8));
                    }
                })
            }
            setLockedStakeInfoInMonth(totalAmount)
            setRewardOneMonthAmount(totalReward)
        }
    }

    // @ts-ignore
    const getLockedStakeInfoInThreeMonth = async (address: string, index: number, stakeValue: number) => {
        const contract = new web3.eth.Contract(bankTokenABI, process.env.REACT_APP_BANK_ADDRESS);
        let totalAmount = 0;
        let totalReward = 0;
        if (web3) {
            for (let i = 0; i < lockedStakeInfoIndexRangeThreeMonth; i++) {
                await contract.methods.getLockedStakeInfo(address, i, 1).call((err: any, res: any) => {
                    if (err) {
                        console.log("Get amount staking fail.", err);
                        return;
                    }
                    if (res) {
                        totalAmount += Number(ethers.utils.formatUnits(res.amount, 8));
                        totalReward += Number(ethers.utils.formatUnits(res.reward, 8));
                    }
                    console.log("SLStake: " + totalAmount, totalReward)
                })
            }
            setLockedStakeInfoInThreeMonth(totalAmount)
            setRewardThreeMonthAmount(totalReward)
        }
    }


    const getLockedStakeInfoIndexRange = async (address: string, index: number, stakeValue: number) => {
        if (web3) {
            const contract = new web3.eth.Contract(bankTokenABI, process.env.REACT_APP_BANK_ADDRESS);

            await contract.methods.getLockedStakeInfoIndexRange(address, 0).call((err: any, res: any) => {
                if (err) {
                    console.log("Get amount staking fail.", err);
                    return;
                }
                if (res) {
                    setLockedStakeInfoIndexRangeOneMonth(res[1])
                }
                // console.log(Object.keys(res).map((key) => res[key]));
            })


            await contract.methods.getLockedStakeInfoIndexRange(address, 1).call((err: any, res: any) => {
                if (err) {
                    console.log("Get amount staking fail.", err);
                    return;
                }
                if (res) {
                    setLockedStakeInfoIndexRangeThreeMonth(res[1])
                }
                console.log(Object.keys(res).map((key) => res[key]));
            })
        }
    }

    /* Get reward stake of user */
    const getRewardRate = async (stakeValue: number) => {
        if (web3) {
            const contract = new web3.eth.Contract(bankTokenABI, process.env.REACT_APP_BANK_ADDRESS);
            await contract.methods.getRewardRate(2).call((err: any, res: any) => {
                if (err) {
                    console.log("Get reward rate fail.", err);
                    return;
                }
                if (res) {
                    setRewardRateFlex(res);
                }
                // console.log("reward rate is: " + (res));
            })

            await contract.methods.getRewardRate(0).call((err: any, res: any) => {
                if (err) {
                    console.log("Get reward rate fail.", err);
                    return;
                }
                if (res) {
                    setRewardRateOneMonth(res);
                }
                // console.log("reward rate is: " + (res));
            })

            await contract.methods.getRewardRate(1).call((err: any, res: any) => {
                if (err) {
                    console.log("Get reward rate fail.", err);
                    return;
                }
                if (res) {
                    setRewardRateThreeMonth(res);
                }
                // console.log("reward rate is: " + (res));
            })
        }
    }

    /* get amount approved of user */
    const getAmountAllowance = async (address: string) => {
        if (address && web3) {
            // @ts-ignore
            const contract = new web3.eth.Contract(phoTokenABI, process.env.REACT_APP_PHO_TOKEN_ADDRESS);
            await contract.methods.allowance(address, process.env.REACT_APP_BANK_ADDRESS).call((err: any, res: any) => {
                if (err) {
                    console.log("Get amount allowance fail", err);
                    return;
                }
                if (res) {
                    setAmountAllowance(Number(ethers.utils.formatUnits(res, 8)));
                }
            })
        }
    }


    /* approve amount */
    const approveAmount = async (phoTokenAmount: number) => {
        let status;
        if (accountAddress && web3) {
            if (phoTokenAmount >= amountStake) {
                setLoading(true);
                const contract = new web3.eth.Contract(phoTokenABI, process.env.REACT_APP_PHO_TOKEN_ADDRESS);
                await contract.methods.approve(
                    process.env.REACT_APP_BANK_ADDRESS,
                    ethers.utils.parseUnits(phoTokenAmount.toString(), 8))
                    .send({
                        from: accountAddress
                    }).on("receipt", (receipt: any) => {
                        if (receipt.status) {
                            setLoading(false);
                            status = "OK";
                        } else {
                            setLoading(false);
                            status = "FAIL";
                        }
                    }).catch((err: any) => {
                        setLoading(false);
                    })
            } else {
                status = "FAIL";
            }
        } else {
            status = "FAIL"
        }
        return status;

    }

    /* validate after stake */
    const onSubmitAmountStake = async (phoTokenAmount: number, dataCt: any) => {
        if (validateStake(phoTokenAmount)) {
            if (amountAllowance < amountStake) {
                setLoading(true);
                const callBackApprove = await approveAmount(phoTokenAmount);
                if (callBackApprove === "OK") {
                    const data = await stake(dataCt);
                    if (data === "OK") {
                        alert("OK");
                    }
                }
            } else {
                setLoading(true);
                const callBackStake = await stake(dataCt);
                if (callBackStake === "OK") {
                    alert("OK");
                }
            }
        }
    }


    const validateStake = (phoTokenAmount: number) => {
        if (Number(amountStake) === 0) {
            alert("Plz enter amount to stake.");
            return false;
        }
        if (isNaN(amountStake)) {
            alert("Plz enter amount to stake.");
            return false;
        }
        if (amountStake > phoTokenAmount) {
            alert("insufficient PHO amount.");
            return false;
        }
        if (!web3) {
            alert("Invalid provider.")
            return false;
        }
        return true;
    }

    /* on stake */
    const stake = async (data: any) => {
        let status;
        // console.log("hhh" + amountStake + stakeValue)
        if (accountAddress && web3) {
            // setShowModal(true);
            const contract = new web3.eth.Contract(bankTokenABI, process.env.REACT_APP_BANK_ADDRESS);
            await contract.methods.stake(ethers.utils.parseUnits(amountStake.toString(), 8), stakeValue).send({
                from: accountAddress

            }).on("receipt", async (receipt: any) => {
                if (receipt.status) {
                    await getFlexibleStakeInfo(accountAddress);
                    await data.getBalancePhoToken(web3, accountAddress);
                    setLoading(false);
                    status = "OK";
                } else {
                    setLoading(false);
                    status = "FAIL";
                }

            }).catch((err: any) => {
                setLoading(false);
            })
        } else {
            status = "FAIL";
        }
        return status;

    }

    const withdrawReward = async (address: string, data: any) => {
        if (address && web3) {
            if (rewardFlexibleAmount > 0 || rewardOneMonthAmount > 0 || rewardThreeMonthAmount > 0) {
                setLoading(true);
                const contract = new web3.eth.Contract(bankTokenABI, process.env.REACT_APP_BANK_ADDRESS);
                await contract.methods.withdrawReward().send({
                    from: address
                }).on("receipt", async (receipt: any) => {
                    if (receipt.status) {
                        // @ts-ignore
                        await getFlexibleStakeInfo(address)
                        // @ts-ignore
                        await getLockedStakeInfoInOneMonth (address)
                        // @ts-ignore
                        await getLockedStakeInfoInThreeMonth (address)
                        await data.getBalancePhoToken(web3, address);
                       

                        setLoading(false)
                        alert("Withdraw reward success");
                    } else {
                        setLoading(false)
                        alert("Withdraw reward fail");
                    }
                }).catch((err: any) => {
                    setLoading(false);
                })
            } else {
                alert("You don't have any reward.");
            }
        } else {
            alert("Plz connect to wallet.");
        }
    }

    const validateUnStake = (address: number, stakeValue: number) => {
        if (flexibleStakeInfo <= 0) {
            alert("insufficient amount");
            return false;
        }
        if (!address) {
            alert("Address invalid");
            return false;
        }
        if (!web3) {
            alert("Invalid provider");
            return false;
        }
        return true;
    }


    const withdrawAmountStake = async (address: number, data: any) => {
        // @ts-ignore
        if (validateUnStake(address)) {
            setLoading(true);
            const contract = new web3.eth.Contract(bankTokenABI, process.env.REACT_APP_BANK_ADDRESS);
            await contract.methods.unstake(ethers.utils.parseUnits(amountUnStake.toString(), 8), stakeValue).send({
                from: address
            }).on("receipt", async (receipt: any) => {
                if (receipt.status) {
                    // @ts-ignore
                    await getFlexibleStakeInfo(address);
                     // @ts-ignore
                    await getLockedStakeInfoInOneMonth(address)
                     // @ts-ignore
                    await getLockedStakeInfoInThreeMonth(address)
                    await data.getBalancePhoToken(web3, address);
                    setLoading(false);
                    alert("Withdraw amount stake success.")
                } else {
                    setLoading(false)
                    alert("Withdraw amount stake fail.")
                }
            }).catch((err: any) => {
                setLoading(false);
            })
        }
    }

    const handleChangeAmountStake = (event: any) => {
        setAmountStake(Number(event.target.value));
    }

    const handleChangeAmountUnStake = (event: any) => {
        setAmountUnStake(Number(event.target.value));
    }

    // @ts-ignore
    const selectValueStake = (event: any) => {
        // @ts-ignore
        setStakeValue(Number(event.target.value));
    }

    return (
        <>
            <DataContext.Consumer>
                {
                    data => (
                        <div className="wrap">
                            <div
                                className={!loading ? "pageLoading d-flex align-items-center justify-content-center text-center d-none" : "pageLoading d-flex align-items-center justify-content-center text-center"}
                            >
                                <div>
                                    <h5 className="font-orbitronRegular mb-3">Please wait...</h5>
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                            <Header />
                            <Sidebar />
                            {/* Content */}
                            <div id="content">
                                <div className="container">
                                    <div className="row staking justify-content-center">
                                        <div className="col-xl-6 col-lg-6">
                                            <div className="item bg-white">
                                                <div className="d-flex justify-content-between align-items-center tokenHeader">
                                                    <div>
                                                        <img src={logo} width={50} />
                                                    </div>
                                                    <div className="text-end">
                                                        <div className="name font-gilroySemiBold textGrad">PHOSWAP FINANCE</div>
                                                        <div className="rate">10x</div>
                                                    </div>
                                                </div>
                                                {/* <div className="d-flex justify-content-between align-items-center mb-2">
                                                    <div className="text">Locked:</div>
                                                    <div className="number">0%
                                                        {
                                                            isNaN(totalStake * 100 / totalSupplyPsyduck) ? 0 : (totalStake * 100 / totalSupplyPsyduck).toFixed(2)
                                                        }%
                                                    </div>
                                                </div> */}
                                                <div className="d-flex justify-content-between align-items-center mb-2">
                                                    <div className="text">APR up to:</div>
                                                    <div className="number">
                                                        2000%
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mb-2">
                                                    <div className="text">Earn:</div>
                                                    <div className="number">PHO</div>
                                                </div>
                                                <hr />
                                                <div className="row d-flex justify-content-between align-items-center mb-2">
                                                    <div className="text col-6 d-flex justify-content-start align-items-center">
                                                        Flexible STAKING:
                                                    </div>
                                                    <div className="text col-2 d-flex justify-content-start align-items-center px-0">
                                                        {
                                                            // @ts-ignore
                                                            data.accountAddress ? rewardRateFlex*400 : 0
                                                        }%
                                                    </div>
                                                    <div className="number col-4 d-flex justify-content-end align-items-center">
                                                        {
                                                            // @ts-ignore
                                                            data.accountAddress ? flexibleStakeInfo : 0
                                                        }
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mb-2">
                                                    <div className="text col-6 d-flex justify-content-start align-items-center">
                                                        1 Month STAKING:
                                                    </div>
                                                    <div className="text col-2 d-flex justify-content-start align-items-center">
                                                        {
                                                            // @ts-ignore
                                                            data.accountAddress ? rewardRateOneMonth*12 : 0
                                                        }%
                                                    </div>
                                                    <div className="number col-4 d-flex justify-content-end align-items-center">
                                                        {
                                                            // @ts-ignore
                                                            data.accountAddress ? lockedStakeInfoInMonth : 0
                                                        }
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mb-2">
                                                    <div className="text col-6 d-flex justify-content-start align-items-center">
                                                        3 Months STAKING:
                                                    </div>
                                                    <div className="text col-2 d-flex justify-content-start align-items-center">
                                                        {
                                                            // @ts-ignore
                                                            data.accountAddress ? rewardRateThreeMonth*4 : 0
                                                        }%
                                                    </div>
                                                    <div className="number col-4 d-flex justify-content-end align-items-center">
                                                        {
                                                            // @ts-ignore
                                                            data.accountAddress ? lockedStakeInfoInThreeMonth : 0
                                                        }</div>
                                                </div>
                                                <hr />
                                                <div className="text">Total PHO earned :</div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="number">
                                                        {
                                                            // @ts-ignore
                                                            data.accountAddress ? (rewardFlexibleAmount + rewardOneMonthAmount + rewardThreeMonthAmount).toFixed(2) : 0
                                                        }
                                                    </div>
                                                    <div>
                                                        <button className="btn red-pink" data-bs-toggle="modal" data-bs-target="#harvestModal"
                                                            onClick={async () => {
                                                                // @ts-ignore
                                                                if (data.accountAddress) {
                                                                    // @ts-ignore
                                                                    // await withdrawReward(data.accountAddress, data);
                                                                } else {
                                                                    // @ts-ignore
                                                                    data.onConnect()
                                                                }
                                                            }}
                                                        >Harvest</button>
                                                    </div>
                                                </div>
                                                <hr />
                                                {/* <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="text">PHO LP Staked:</div>
                                                    <div className="number">200</div>
                                                </div> */}
                                                <div className="row mb-3">
                                                    <div className="col-md-6 d-grid">
                                                        <button className="btn blue-cyan" data-bs-toggle="modal" data-bs-target="#stakeModal"
                                                            onClick={() => {
                                                                // @ts-ignore
                                                                if (data.accountAddress) {
                                                                    // setShowModal(true);
                                                                } else {
                                                                    // @ts-ignore
                                                                    data.onConnect()
                                                                }
                                                            }}
                                                        >
                                                            Stake
                                                        </button>
                                                    </div>
                                                    <div className="col-md-6 d-grid">
                                                        <button className="btn red-pink" data-bs-toggle="modal" data-bs-target="#unStakeModal"
                                                            onClick={() => {
                                                                // @ts-ignore
                                                                if (data.accountAddress) {
                                                                    // setShowModalUnStake(true);
                                                                } else {
                                                                    // @ts-ignore
                                                                    data.onConnect()
                                                                }
                                                            }}
                                                        >
                                                            Unstake
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="d-grid">
                                                    <button className="btn blue-cyan" type="button"

                                                        onClick={async () => {
                                                            // @ts-ignore
                                                            if (data.accountAddress) {
                                                                // @ts-ignore
                                                                await approveAmount(Number(data.phoTokenAmount));
                                                            } else {
                                                                // @ts-ignore
                                                                data.onConnect()
                                                            }
                                                        }}>
                                                        Approve Contract
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Coming soon Modal */}
                            <div className="modal fade" id="comingSoonModal" tabIndex={-1} aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="d-flex justify-content-between align-items-center mb-5">
                                                <h5 className="text-uppercase mb-0">Swap</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                            </div>
                                            <h5 className="text-center mb-4">
                                                Coming soon
                                            </h5>
                                            <div className="d-grid mt-4">
                                                <button type="button" className="btn red-pink" data-bs-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Stake Modal */}
                            <div className="modal fade" id="stakeModal" tabIndex={-1} aria-labelledby="staticBackdropLabel" style={{ display: 'none' }} aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="d-flex justify-content-between align-items-center mb-5">
                                                <h5 className="text-uppercase mb-0">Stake PHO</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-2 fw-bold">
                                                <div>
                                                    {
                                                        // @ts-ignore
                                                        data.accountAddress.slice(0, 4) + '...' + data.accountAddress.slice(data.accountAddress.length - 4, data.accountAddress.length)
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        // @ts-ignore
                                                        data.phoTokenAmount
                                                    }
                                                    PHO</div>
                                            </div>
                                            <input type="number" className="form-control" placeholder="Enter amount to stake"
                                                onChange={(e) => handleChangeAmountStake(e)}
                                            />
                                            <div className="text-danger mt-2 small">
                                                {textValidate}
                                            </div>
                                            <select defaultValue={'DEFAULT'} id="stakeValue" onChange={(e) => selectValueStake(e)} className="form-select form-control mt-2" aria-label="Default select example">
                                                <option selected value="DEFAULT">Choose token lock time</option>
                                                <option value="0">1 Month Stake</option>
                                                <option value="1">3 Month Stake</option>
                                                <option value="2">Flexible Stake</option>
                                            </select>
                                            {/* <div className="text-danger mt-1 small">Amount is invalid</div> */}
                                            <div className="d-grid mt-4">
                                                <button type="button" className="btn blue-cyan"
                                                    onClick={async () => {
                                                        // @ts-ignore
                                                        if (data.accountAddress) {
                                                            // @ts-ignore
                                                            await onSubmitAmountStake(Number(data.phoTokenAmount), data)
                                                        } else {
                                                            // @ts-ignore
                                                            data.onConnect()
                                                            // closeModal()
                                                        }
                                                    }}
                                                >Stake</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* UnStake Modal */}
                            <div className="modal fade" id="unStakeModal" tabIndex={-1} aria-labelledby="staticBackdropLabel" style={{ display: 'none' }} aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="d-flex justify-content-between align-items-center mb-5">
                                                <h5 className="text-uppercase mb-0">UnStake PHO</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-2 fw-bold">
                                                <div>
                                                    {
                                                        // @ts-ignore
                                                        data.accountAddress.slice(0, 4) + '...' + data.accountAddress.slice(data.accountAddress.length - 4, data.accountAddress.length)
                                                    }
                                                </div>
                                                <div>Balance:
                                                    <span className="mx-3">
                                                        {
                                                            // @ts-ignore
                                                            data.phoTokenAmount
                                                        }
                                                    </span>

                                                    PHO</div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-2 fw-bold">
                                                <div>Flexible Staked:</div>
                                                <div>
                                                    <span className="mx-3">
                                                        {
                                                            // @ts-ignore
                                                            data.accountAddress ? flexibleStakeInfo : 0
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-2 fw-bold">
                                                <div>1 Months Staked:</div>
                                                <div>
                                                    <span className="mx-3">
                                                        {
                                                            // @ts-ignore
                                                            data.accountAddress ? lockedStakeInfoInMonth : 0
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-2 fw-bold">
                                                <div>3 Months Staked:</div>
                                                <div>
                                                    <span className="mx-3">
                                                        {
                                                            // @ts-ignore
                                                            data.accountAddress ? lockedStakeInfoInThreeMonth : 0
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <input type="number" className="form-control" placeholder="Enter amount to unstake"
                                                onChange={(e) => handleChangeAmountUnStake(e)}
                                            />
                                            <div className="text-danger mt-2 small">
                                                {textValidate}
                                            </div>
                                            <select defaultValue={'DEFAULT'} id="stakeValue" onChange={(e) => selectValueStake(e)} className="form-select form-control mt-2" aria-label="Default select example">
                                                <option value="DEFAULT">Choose token Unstake</option>
                                                <option value="0">1 Month UnStake</option>
                                                <option value="1">3 Month UnStake</option>
                                                <option selected value="2">Flexible UnStake</option>
                                            </select>
                                            {/* <div className="text-danger mt-1 small">Amount is invalid</div> */}
                                            <div className="d-grid mt-4">
                                                <button type="button" className="btn blue-cyan"
                                                    onClick={async () => {
                                                        // @ts-ignore
                                                        if (data.accountAddress) {
                                                            // @ts-ignore
                                                            await withdrawAmountStake(data.accountAddress, data)
                                                        } else {
                                                            // @ts-ignore
                                                            data.onConnect()
                                                        }
                                                    }}
                                                >UnStake</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Harvest Modal */}
                            <div className="modal fade" id="harvestModal" tabIndex={-1} aria-labelledby="staticBackdropLabel" style={{ display: 'none' }} aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="d-flex justify-content-between align-items-center mb-5">
                                                <h5 className="text-uppercase mb-0">Stake PHO</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-2 fw-bold">
                                                <div>
                                                    {
                                                        // @ts-ignore
                                                        data.accountAddress.slice(0, 4) + '...' + data.accountAddress.slice(data.accountAddress.length - 4, data.accountAddress.length)
                                                    }
                                                </div>
                                                <div>Balance:
                                                    <span className="mx-3">
                                                        {
                                                            // @ts-ignore
                                                            data.phoTokenAmount
                                                        }
                                                    </span>
                                                    PHO
                                                </div>

                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-2 fw-bold">
                                                <div>Flexible Reward:</div>
                                                <div>
                                                    <span className="mx-3">
                                                        {
                                                            // @ts-ignore
                                                            data.accountAddress ? rewardFlexibleAmount : 0
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-2 fw-bold">
                                                <div>One Month Reward:</div>
                                                <div>
                                                    <span className="mx-3">
                                                        {
                                                            // @ts-ignore
                                                            data.accountAddress ? rewardOneMonthAmount : 0
                                                        }
                                                    </span>

                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-2 fw-bold">
                                                <div>Three Month Reward:</div>
                                                <div>
                                                    <span className="mx-3">
                                                        {
                                                            // @ts-ignore
                                                            data.accountAddress ? rewardThreeMonthAmount  : 0
                                                        }
                                                    </span>

                                                </div>
                                            </div>
                                            {/* <input type="number" className="form-control" placeholder="Enter amount to unstake"
                                                onChange={(e) => handleChangeAmountUnStake(e)}
                                            />
                                            <div className="text-danger mt-2 small">
                                                {textValidate}
                                            </div>
                                            <select defaultValue={'DEFAULT'} id="stakeValue" onChange={(e) => selectValueStake(e)} className="form-select form-control mt-2" aria-label="Default select example">
                                                <option value="DEFAULT">Choose token Unstake</option>
                                                <option value="0">1 Month UnStake</option>
                                                <option value="1">3 Month UnStake</option>
                                                <option selected value="2">Flexible UnStake</option>
                                            </select> */}
                                            {/* <div className="text-danger mt-1 small">Amount is invalid</div> */}
                                            <div className="d-grid mt-4">
                                                <button type="button" className="btn red-pink"
                                                    onClick={async () => {
                                                        // @ts-ignore
                                                        if (data.accountAddress) {
                                                            // @ts-ignore
                                                            await withdrawReward(data.accountAddress, data);
                                                        } else {
                                                            // @ts-ignore
                                                            data.onConnect()
                                                        }
                                                    }}
                                                >Harvest</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    )
                }
            </DataContext.Consumer>
        </>
    );
}

export default Stake;

