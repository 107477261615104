import DataContext from "../../context/context";
import pancakelogo from "../../../assest/img/logo-pancakeswap.png";
import bscscanlogo from "../../../assest/img/logo-bscscan.png";
import certiklogo from "../../../assest/img/logo-certik.png";
import hotbitlogo from "../../../assest/img/icon-hotbit.png"
import coinmarketcap from "../../../assest/img/icon-cmc.png"
import telegram from "../../../assest/img/icon-telegram.png"
import telegramPhoVN from "../../../assest/img/icon-telegram.png"
import discord from "../../../assest/img/icon-discord.png"
import twitter from "../../../assest/img/icon-twitter.png"
function Footer() {

    return (
        <DataContext.Consumer>
            {data => (
                <div className="wrap">
                    {/* Footer */}
                    <footer>
                        <div className="social">
                            <a href="https://bscscan.com/token/0xb9784c1633ef3b839563b988c323798634714368" target="_blank" className="orange-deep">
                                <img src={bscscanlogo} alt="" />
                            </a>
                            <a href="https://pancakeswap.finance/swap?outputCurrency=0xb9784c1633ef3b839563b988c323798634714368" target="_blank" className="indigo-light-blue">
                                <img src={pancakelogo} alt="" />
                            </a>
                            <a href="https://www.certik.com/projects/phoswap" target="_blank" className="purple-deep">
                                <img src={certiklogo} alt="" />
                            </a>
                            <a href="https://www.hotbit.io/exchange?symbol=PHO_USDT" target="_blank" className="blue-cyan">
                                <img src={hotbitlogo} alt="" />
                            </a>
                            <a href="https://coinmarketcap.com/currencies/phoswap" target="_blank" className="red-pink">
                                <img src={coinmarketcap} alt="" />
                            </a>
                            <a href="https://t.me/phoswap" target="_blank" className="amber">
                                <img src={telegram} alt="" />
                            </a>
                            <a href="https://t.me/phoswapvietnam" target="_blank" className="blue-cyan">
                                <img src={telegramPhoVN} alt="" />
                            </a>
                            <a href="https://discord.com/invite/svUyvAVbgw" target="_blank" className="green-teal">
                                <img src={discord} alt="" />
                            </a>
                            <a href="https://twitter.com/phoswapofficial" target="_blank" className="purple-amber">
                                <img src={twitter} alt="" />
                            </a>
                        </div>
                    </footer>

                </div>
            )}
        </DataContext.Consumer>
    );
}

export default Footer;
