import React, { useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
// import Stake from "../stake/stake";
import DataContext from "../../context/context";
import Header from "../../layout/header/header";
import logo from "../../../assest/img/logo.png";
import pancakelogo from "../../../assest/img/logo-pancakeswap.png";
import bscscanlogo from "../../../assest/img/logo-bscscan.png";
import certiklogo from "../../../assest/img/logo-certik.png";
import { Link, Redirect } from "react-router-dom";

function Sidebar() {
    return (
        <DataContext.Consumer>
            {data => (
                <div className="wrap">
                    <Header />
                    <div>
                        {/* Sidebar menu */}
                        <aside>
                            <div id="logo">
                                <img src={logo} alt="" /> PHOSWAP FINANCE
                            </div>
                            <ul className="mainmenu list-unstyled d-flex flex-column">
                                <li>
                                    <Link to="/home" className="active">
                                        <svg className="icon" style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M19,5V7H15V5H19M9,5V11H5V5H9M19,13V19H15V13H19M9,17V19H5V17H9M21,3H13V9H21V3M11,3H3V13H11V3M21,11H13V21H21V11M11,15H3V21H11V15Z" />
                                        </svg> Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/staking" className="active">
                                        <svg className="icon" style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M15 10C15 9.45 15.45 9 16 9C16.55 9 17 9.45 17 10S16.55 11 16 11 15 10.55 15 10M8 9H13V7H8V9M22 7.5V14.47L19.18 15.41L17.5 21H12V19H10V21H4.5C4.5 21 2 12.54 2 9.5S4.46 4 7.5 4H12.5C13.41 2.79 14.86 2 16.5 2C17.33 2 18 2.67 18 3.5C18 3.71 17.96 3.9 17.88 4.08C17.74 4.42 17.62 4.81 17.56 5.23L19.83 7.5H22M20 9.5H19L15.5 6C15.5 5.35 15.59 4.71 15.76 4.09C14.79 4.34 14 5.06 13.67 6H7.5C5.57 6 4 7.57 4 9.5C4 11.38 5.22 16.15 6 19H8V17H14V19H16L17.56 13.85L20 13.03V9.5Z" />
                                        </svg> Staking
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/"} className="active" data-bs-toggle="modal" data-bs-target="#comingSoonModal">
                                        <svg className="icon" style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M4,6L8,10V7H16A2,2 0 0,1 18,9A2,2 0 0,1 16,11H8A4,4 0 0,0 4,15A4,4 0 0,0 8,19H16V22L20,18L16,14V17H8A2,2 0 0,1 6,15A2,2 0 0,1 8,13H16A4,4 0 0,0 20,9A4,4 0 0,0 16,5H8V2L4,6Z" />
                                        </svg> Swap
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/"} className="active" data-bs-toggle="modal" data-bs-target="#comingSoonModal">
                                        <svg className="icon" style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M5,4V11.26C3.2,11.9 2,13.6 2,15.5C2,18 4,20 6.5,20C8.79,20 10.71,18.28 10.97,16H15.17C15.06,16.32 15,16.66 15,17A3,3 0 0,0 18,20A3,3 0 0,0 21,17C21,16.66 20.94,16.32 20.82,16H22V13C22,11.89 21.11,11 20,11H15.04L13.65,4H5M7,6H12L13,11V14H10.74C10.16,12.38 8.71,11.23 7,11.03V6M6.5,13.25A2.25,2.25 0 0,1 8.75,15.5A2.25,2.25 0 0,1 6.5,17.75A2.25,2.25 0 0,1 4.25,15.5A2.25,2.25 0 0,1 6.5,13.25M18,15.5A1.5,1.5 0 0,1 19.5,17A1.5,1.5 0 0,1 18,18.5A1.5,1.5 0 0,1 16.5,17A1.5,1.5 0 0,1 18,15.5Z" />
                                        </svg> Farm
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/"} className="active" data-bs-toggle="modal" data-bs-target="#comingSoonModal">
                                        <svg className="icon" style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M19 22H5V20H19V22M16 18H8L10.18 10H8V8H10.72L10.79 7.74C10.1 7.44 9.55 6.89 9.25 6.2C8.58 4.68 9.27 2.91 10.79 2.25C12.31 1.58 14.08 2.27 14.74 3.79C15.41 5.31 14.72 7.07 13.2 7.74L13.27 8H16V10H13.82L16 18Z" />
                                        </svg> NFT
                                    </Link>
                                </li>
                                <li className="mt-auto">
                                    <a href="https://pancakeswap.finance/swap?outputCurrency=0xb9784c1633ef3b839563b988c323798634714368" target="_blank">
                                        <img src={pancakelogo} className="icon" /> Buy On Pancakeswap
                                    </a>
                                </li>
                                <li>
                                    <a href="https://bscscan.com/token/0xb9784c1633ef3b839563b988c323798634714368" target="_blank">
                                        <img src={bscscanlogo} className="icon" /> View on Bscscan
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.certik.com/projects/phoswap" target="_blank">
                                        <img src={certiklogo} className="icon" /> Audited by CertiK
                                    </a>
                                </li>
                            </ul>
                        </aside>
                    </div>

                </div>
            )}
        </DataContext.Consumer>
    );
}

export default Sidebar;
