import React, { useEffect, useState } from "react";
import logo from "../../../assest/img/logo.png"
import { Link } from 'react-router-dom'
import DataContext from "../../context/context";
import { Modal } from "react-bootstrap";
// @ts-ignore
import $ from "jquery";

// @ts-ignore
function Header(thisPage) {
    return (
        <DataContext.Consumer>
            {
                data => (
                    <>
                        {/* <!-- Header --> */}
                        <div>

                            <header className="d-flex align-items-center justify-content-end">
                                {
                                    // @ts-ignore
                                    !data.accountAddress ? (
                                        <button className="btn blue-cyan"
                                            onClick={async () => {
                                                // @ts-ignore
                                                await data.onConnect();
                                            }}
                                        >Connect Wallet</button>
                                    ) : (
                                        <div className="d-flex justify-content-center align-items-center">
                                            
                                            {/* <span>
                                                Balance: 
                                                {
                                                    // @ts-ignore
                                                    data.phoTokenAmount
                                                }
                                                   PHO
                                            </span> */}
                                            <button className="btn blue-cyan m-2" data-bs-toggle="modal" data-bs-target="#walletModal">
                                                {
                                                    // @ts-ignore
                                                    data.accountAddress.slice(0, 4) + '.....' + data.accountAddress.slice(data.accountAddress.length - 4, data.accountAddress.length)
                                                }
                                            </button>

                                        </div>
                                    )
                                }

                                <div>
                                    {
                                        // @ts-ignore 
                                        data.ownerAddress != 0xA69F97fa41F24352F60cc63891481aC9a79090ce ?
                                            (
                                                <div className="m-2">
                                                    {/* <Link to={""} className="btn amber">Nothing</Link> */}
                                                </div>
                                            ) : (
                                                <div className="m-2">
                                                    <Link to={"/admin"} className="btn amber ">Dashboard</Link>
                                                </div>
                                            )
                                    }
                                </div>


                            </header>
                            {/* Wallet Modal */}
                            <div className="modal fade" id="walletModal" tabIndex={-1} aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="d-flex justify-content-between align-items-center mb-5">
                                                <h5 className="text-uppercase mb-0">Wallet connected</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                            </div>
                                            <div className="d-flex justify-content-between mb-4">
                                                <div>
                                                    <div className="fw-bold mb-1">Address</div>
                                                    <div>
                                                        {
                                                            // @ts-ignore
                                                            data.accountAddress.slice(0, 4) + '...' + data.accountAddress.slice(data.accountAddress.length - 4, data.accountAddress.length)
                                                        }
                                                    </div>
                                                </div>
                                                <div className="text-end">
                                                    <div className="fw-bold mb-1">Total PHO</div>
                                                    <div>Balance:
                                                        {
                                                            // @ts-ignore
                                                            data.phoTokenAmount
                                                        } PHO <img src={logo} width={20} /></div>
                                                </div>
                                            </div>
                                            <div className="d-grid mt-4">
                                                <button type="button" className="btn red-pink" data-bs-dismiss="modal"
                                                    onClick={() => {
                                                        // @ts-ignore
                                                        data.changeValueAccountAddress("");
                                                    }}
                                                >Disconnect</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                )
            }
        </DataContext.Consumer>
    );
}

export default Header;

