import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Stake from "../stake/stake";
import DataContext from "../../context/context";
import Header from "../../layout/header/header";
import { Link, Redirect } from "react-router-dom";
import Sidebar from "../sidebar/sidebar"
import Footer from "../footer/footer"

function Home() {

    // Show Modal Connect
    const [showModal, setShowModal] = useState(false);
    // Close Modal Connect
    const closeModal = () => {
        setShowModal(false);
    }

    return (
        <DataContext.Consumer>
            {data => (
                <div className="wrap">
                    <Header />
                    {/* <!-- Sidebar menu --> */}
                    <div>
                        {/* Sidebar menu */}
                        <Sidebar />

                        {/* Content */}
                        <div id="content">
                            <div className="container">
                                <div className="row overview">
                                    <div className="col-md-4 item">
                                        <div className="inner bg-white">
                                            <h3 className="title">Phoswap Finance is DeFi on Binance Smart Chain.</h3>
                                            <p className="desc">Swap, Farm, NFT Market and more... Welcome to Phoswap!</p>
                                            <a href="#" target="_blank" className="btn blue-cyan" data-bs-toggle="modal" data-bs-target="#comingSoonModal">Swap now</a>
                                        </div>
                                    </div>
                                    <div className="col-md-4 item">
                                        <div className="inner bg-white">
                                            <h3 className="title">Enjoy PHO staking up to 2000% APR.</h3>
                                            <p className="desc">Grab some PHO and start staking today!</p>
                                            <Link to={"/staking"} className="btn blue-cyan">Stake now</Link>
                                        </div>
                                    </div>
                                    <div className="col-md-4 item">
                                        <div className="inner bg-white">
                                            <h3 className="title">Interested in PHO NFTs?</h3>
                                            <p className="desc">No problem Available PHO NFTs coming soon!</p>
                                            <a href="#" className="btn blue-cyan"  data-bs-toggle="modal" data-bs-target="#comingSoonModal">NFT Market</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Coming soon Modal */}
                        <div className="modal fade" id="comingSoonModal" tabIndex={-1} aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="d-flex justify-content-between align-items-center mb-5">
                                            <h5 className="text-uppercase mb-0">Swap</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                        </div>
                                        <h5 className="text-center mb-4">
                                            Coming soon
                                        </h5>
                                        <div className="d-grid mt-4">
                                            <button type="button" className="btn red-pink" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            )}
        </DataContext.Consumer>
    );
}

export default Home;
