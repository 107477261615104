import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './assest/css/style.css'
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Home from "./component/screen/home/home";
import Stake from "./component/screen/stake/stake";
import Admin from "./component/admin/adminpage"
import { Route, Redirect, Switch, BrowserRouter } from 'react-router-dom'
import DataContext from "./component/context/context";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Authereum from "authereum";
import Web3 from "web3";
import { ethers } from "ethers";

const userRouter = [
    {
        path: '/home',
        component: Home,
    },
    {
        path: '/staking',
        component: Stake,
    }
]

const adminRouter = [
    {
        path: '/home',
        component: Home,
    },
    {
        path: '/staking',
        component: Stake,
    },
    {
        path: '/admin',
        component: Admin,
    },
]


function App() {
    const web3Modal = new Web3Modal({
        cacheProvider: false,
        providerOptions: {
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    infuraId: "8043bb2cf99347b1bfadfb233c5325c0",
                }
            }
        },
        disableInjectedProvider: false,
    });
    const [web3, setWeb3] = useState(Web3.givenProvider);
    const [provider, setProvider] = useState();
    const [accountAddress, setAccountAddress] = useState('');
    const [accountBalance, setAccountBalance] = useState(0);
    const [phoTokenAmount, setPhoTokenAmount] = useState(0);
    const [ownerAddress, setOwnerAddress] = useState('');

    // @ts-ignore
    const [bankTokenABI, setBankTokenABI] = useState(JSON.parse(process.env.REACT_APP_BANK_TOKEN_ABI));
    // @ts-ignore
    const [phoTokenABI, setPhoTokenABI] = useState(JSON.parse(process.env.REACT_APP_PHO_TOKEN_ABI));

    // @ts-ignore
    useEffect(async () => {
        setProvider(Web3.givenProvider);
        await setProviderAndWeb3(Web3.givenProvider);
    }, [])

    //@ts-ignore
    useEffect(async () => {
        await getAccountAddress();
        console.log(accountAddress)
    }, [accountAddress])

    // @ts-ignore
    useEffect(async () => {
        if (provider) {
            // @ts-ignore
            provider.on("accountsChanged", async (accounts) => {
                // @ts-ignore
                if (accounts[0]) {
                    const address = accounts[0].toLowerCase();
                    setAccountAddress(address);
                    setOwnerAddress(address);
                    await getAccountBalance(address);
                    await getBalancePhoToken(web3, address);
                } else {
                    setAccountAddress('');
                    setAccountBalance(0);
                    setPhoTokenAmount(0);
                    setOwnerAddress('');

                }
            });
        }
    }, [provider])

    // @ts-ignore
    useEffect(async () => {
        if (provider) {
            // @ts-ignore
            provider.on("chainChanged", async (chainId) => {
                /* test net "0x61" main net "0x38...." */
                if (chainId.toString() !== "0x38") {
                    setAccountAddress('');
                    setAccountBalance(0);
                    setPhoTokenAmount(0);
                    setOwnerAddress('');
                } else {
                    if (web3) {
                        const accountAddress = await web3.eth.getAccounts();
                        if (accountAddress[0]) {
                            const address = accountAddress[0].toLowerCase();
                            setAccountAddress(address);
                            setOwnerAddress(address);
                            await getAccountBalance(address);
                            await getBalancePhoToken(web3, address);
                            await getOwnerAddress();
                        }
                    }
                }
            });
        }
    }, [provider])

    // @ts-ignore
    useEffect(async () => {
        if (window.ethereum) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x38' }], // test net "0x3" main net "0x1"
                }).then((res: any) => {
                    console.log(res);
                });
            } catch (error: any) {
                if (error.code === 4902) {
                    try {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId: '0x38',
                                },
                            ],
                        });
                    } catch (addError) {
                        console.error(addError);
                    }
                }
                console.error(error);
            }
        } else {
            alert('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
        }
    }, [])

    const getBalancePhoToken = async (web3: any, address: string) => {
        if (web3 && address) {
            const contract = new web3.eth.Contract(phoTokenABI, process.env.REACT_APP_PHO_TOKEN_ADDRESS);
            await contract.methods.balanceOf(address).call((err: any, res: any) => {
                if (err) {
                    console.log("Get balance PHO token fail. ", err);
                    return;
                }
                if (res) {
                    setPhoTokenAmount(Number(ethers.utils.formatUnits(res, 8)));
                }
            })
        } else {
            alert("Please connect to wallet");
        }
    }

    async function onConnect() {
        let pd;
        try {
            if (!accountAddress) {
                web3Modal.clearCachedProvider();
                pd = await web3Modal.connect();
                setProvider(pd);
            }
        } catch (e) {
            console.log("Could not get a wallet connection", e);
            return;
        }
        if (pd) {
            await setProviderAndWeb3(pd);
        }
    }

    const setProviderAndWeb3 = async (provider: any) => {
        const web = new Web3(provider);
        setWeb3(web);
        const accountAddress = await web.eth.getAccounts();
        if (accountAddress[0]) {
            setAccountAddress(accountAddress[0].toLowerCase());
            setOwnerAddress(accountAddress[0].toLowerCase());
            await getBalancePhoToken(web, accountAddress[0].toLowerCase());
            await getAccountBalance(accountAddress[0].toLowerCase());
        }
    }

    const getOwnerAddress = async () => {
        if (web3) {
            const contract = new web3.eth.Contract(bankTokenABI, process.env.REACT_APP_BANK_ADDRESS);
            await contract.methods.owner().call((err: any, res: any) => {
                if (err) {
                    console.log("Get address owner fail.", err);
                    return;
                }
                if (res) {
                    setOwnerAddress(res);
                    // alert("You are logged in as an account administrator.!")
                }
                console.log("dia chi onwner:" + (res))
            })
        }
    }

    const getAccountAddress = async () => {
        // @ts-ignore
        if (web3.eth) {
            await getAccountBalance(accountAddress);
        }
    }

    const getAccountBalance = async (address: string) => {
        // @ts-ignore
        if (web3.eth && address) {
            // @ts-ignore
            const accountBalance = await web3.eth.getBalance(address);
            setAccountBalance(Number(ethers.utils.formatUnits(accountBalance, 8)));
        }
    }

    const changeValueAccountAddress = (value: string) => {
        setAccountAddress(value);
    }

    return (
        <>
            <DataContext.Provider
                value={{
                    onConnect: onConnect,
                    accountAddress: accountAddress,
                    accountBalance: accountBalance,
                    phoTokenAmount: phoTokenAmount,
                    ownerAddress: ownerAddress,
                    getBalancePhoToken: getBalancePhoToken,
                    getOwnerAddress: getOwnerAddress,
                    changeValueAccountAddress: changeValueAccountAddress,
                }}
            >
                <BrowserRouter>
                    <Switch>
                        {
                            accountAddress.toLowerCase() !== "0xA69F97fa41F24352F60cc63891481aC9a79090ce".toLowerCase() ? (
                                <>
                                    {
                                        userRouter.map((route, index) => (
                                            <Route path={route.path} key={index}>
                                                <route.component />
                                            </Route>
                                        ))
                                    }
                                    <Redirect from="*" to="/home" />
                                    <Redirect from="/" to="/home"/>
                                </>
                            ) : (
                                <>
                                    {
                                        adminRouter.map((route, index) => (
                                            <Route path={route.path} key={index}>
                                                <route.component />
                                            </Route>
                                        ))
                                    }
                                    <Redirect from="*" to="/home" />
                                    <Redirect from="/" to="/home"/>
                                </>
                            )
                        }
                    </Switch>
                </BrowserRouter>
            </DataContext.Provider>
        </>
    );
}

export default App;
