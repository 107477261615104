import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { ethers } from "ethers";
import DataContext from "../context/context";
import Header from "../layout/header/header";
import { Link, Redirect } from "react-router-dom";
import Sidebar from "../screen/sidebar/sidebar"
import Footer from "../screen/footer/footer"
import Stake from "../screen/stake/stake";

function Admin() {

    const web3 = new Web3(Web3.givenProvider);
    const [accountAddress, setAccountAddress] = useState('');
    const [forceUnstake, setForceUnstake] = useState('');
    const [forceAllUnstake, setforceAllUnstake] = useState('');
    const [loading, setLoading] = useState(false);
    const [rewardRate, setRewardRate] = useState(0);
    const [amountRewardRate, setAmountRewardRate] = useState(0);
    const [selectStakeType, setSelectStakeType] = useState('');
    const [transferAddress, setTransferAddress] = useState('');
    const [amountTransfer, setAmountTransfer] = useState(0);
    const [amountAllowance, setAmountAllowance] = useState(0);


    // @ts-ignore
    const [bankTokenABI, setBankTokenABI] = useState(JSON.parse(process.env.REACT_APP_BANK_TOKEN_ABI));
    // @ts-ignore
    const [phoTokenABI, setPhoTokenABI] = useState(JSON.parse(process.env.REACT_APP_PHO_TOKEN_ABI));


    // @ts-ignore
    useEffect(async () => {
        // @ts-ignore
        Web3.givenProvider.on("accountsChanged", async (accounts: string[]) => {
            // @ts-ignore
            if (accounts[0]) {
                const address = accounts[0].toLowerCase();
                setRewardRate(0);
                setAccountAddress('');
                await getAmountAllowance(address);
            } else {
            }
        });
    }, [])

    // @ts-ignore
    useEffect(async () => {
        // @ts-ignore
        Web3.givenProvider.on("chainChanged", async (chainId: number) => {
            /* test net "0x3" main net "0x1" */
            if (chainId.toString() !== "0x38") {
                setAccountAddress('');
                // @ts-ignore
                await getAmountAllowance(address);
            } else {
                const accountAddress = await web3.eth.getAccounts();
                if (accountAddress[0]) {
                    const address = accountAddress[0].toLowerCase();
                    setAccountAddress(address);
                }
            }
        });
    }, [])

    // @ts-ignore
    useEffect(async () => {
        if (web3) {
            const accountAddress = await web3.eth.getAccounts();
            if (accountAddress[0]) {
                const address = accountAddress[0].toLowerCase();
                setAccountAddress(address)
                await getAmountAllowance(address);
            }
        }
    }, [])

    /* approve amount */
    const approveAmount = async (phoTokenAmount: number) => {
        let status;
        if (accountAddress && web3) {
            if (phoTokenAmount >= amountTransfer) {
                setLoading(true);
                const contract = new web3.eth.Contract(phoTokenABI, process.env.REACT_APP_PHO_TOKEN_ADDRESS);
                await contract.methods.approve(
                    process.env.REACT_APP_BANK_ADDRESS,
                    ethers.utils.parseUnits(phoTokenAmount.toString(), 8))
                    .send({
                        from: accountAddress
                    }).on("receipt", (receipt: any) => {
                        if (receipt.status) {
                            setLoading(false);
                            status = "OK";
                        } else {
                            setLoading(false);
                            status = "FAIL";
                        }
                    }).catch((err: any) => {
                        setLoading(false);
                    })
                console.log("Hahahehe" + phoTokenAmount)
            } else {
                status = "FAIL";
            }
        } else {
            status = "FAIL"
        }
        return status;

    }

    const getAmountAllowance = async (address: string) => {
        if (address && web3) {
            // @ts-ignore
            const contract = new web3.eth.Contract(phoTokenABI, process.env.REACT_APP_PHO_TOKEN_ADDRESS);
            await contract.methods.allowance(address, process.env.REACT_APP_BANK_ADDRESS).call((err: any, res: any) => {
                if (err) {
                    console.log("Get amount allowance fail", err);
                    return;
                }
                if (res) {
                    setAmountAllowance(Number(ethers.utils.formatUnits(res, 8)));
                }
            })
        }
    }

    const transfer = async (data: any) => {
        let status;
        if (accountAddress && web3) {
            const contract = new web3.eth.Contract(phoTokenABI, process.env.REACT_APP_PHO_TOKEN_ADDRESS);
            await contract.methods.transfer(process.env.REACT_APP_BANK_ADDRESS, ethers.utils.parseUnits(amountTransfer.toString(), 8)).send({
                from: accountAddress
            }).on("receipt", async (receipt: any) => {
                if (receipt.status) {
                    setLoading(false);
                    status = "OK";
                } else {
                    setLoading(false);
                    status = "FAIL";
                }
            }).catch((err: any) => {
                setLoading(false);
            })
        } else {
            status = "FAIL";
        }
        // console.log("tranfer123: " + amountTransfer);

        return status;

    }

    const onSubmitAmountTransfer = async (phoTokenAmount: number, dataCt: any) => {
        if (validateTransfer(phoTokenAmount)) {
            if (amountAllowance < amountTransfer) {
                setLoading(true);
                const callBackApprove = await approveAmount(phoTokenAmount);
                if (callBackApprove === "OK") {
                    const data = await transfer(dataCt);
                    if (data === "OK") {
                        alert("OK");
                    }
                }
            } else {
                setLoading(true);
                const callBackStake = await transfer(dataCt);
                if (callBackStake === "OK") {
                    alert("OK");
                }
            }
        }
    }

    const validateTransfer = (phoTokenAmount: number) => {
        if (Number(amountTransfer) === 0) {
            alert("Plz enter amount transfer.");
            return false;
        }
        if (isNaN(amountTransfer)) {
            alert("Plz enter amount transfer.");
            return false;
        }
        if (amountTransfer > phoTokenAmount) {
            alert("insufficient PHO amount.");
            return false;
        }
        if (!web3) {
            alert("Invalid provider.")
            return false;
        }
        return true;
    }


    const getForceUnstake = async (address: string) => {
        // @ts-ignore
        if (address) {
            setLoading(true);
            const contract = new web3.eth.Contract(bankTokenABI, process.env.REACT_APP_BANK_ADDRESS);
            await contract.methods.forceUnstake(address).send({
                from: address
            }).on("receipt", async (receipt: any) => {
                if (receipt.status) {
                    // @ts-ignore
                    setLoading(false);
                    alert("Withdraw amount stake success.")
                } else {
                    setLoading(false)
                    alert("Withdraw amount stake fail.")
                }
            }).catch((err: any) => {
                setLoading(false);
            })
        }
    }

    const getforceAllUnstake = async (address: string) => {
        // @ts-ignore
        if (address) {
            setLoading(true);
            const contract = new web3.eth.Contract(bankTokenABI, process.env.REACT_APP_BANK_ADDRESS);
            await contract.methods.forceAllUnstake().send({
                from: address
            }).on("receipt", async (receipt: any) => {
                if (receipt.status) {
                    // @ts-ignore
                    setLoading(false);
                    alert("Withdraw amount stake success.")
                } else {
                    setLoading(false)
                    alert("Withdraw amount stake fail.")
                }
            }).catch((err: any) => {
                setLoading(false);
            })
        }
    }

    /* Set Reward Rate */
    const SetRewardRate = async (stakeValue: number, rewardRate: number) => {
        if (stakeValue) {
            setLoading(true);
            const contract = new web3.eth.Contract(bankTokenABI, process.env.REACT_APP_BANK_ADDRESS);
            await contract.methods.setRewardRate(selectStakeType, amountRewardRate).send({
                from: stakeValue
            }).on("receipt", async (receipt: any) => {
                if (receipt.status) {
                    // @ts-ignore
                    setLoading(false);
                    alert("Set amount reward rate success.")
                } else {
                    setLoading(false)
                    alert("Set amount reward rate fail.")
                }
            }).catch((err: any) => {
                setLoading(false);
            })
            // console.log("set reward OK" + selectStakeType, amountRewardRate)
        }

    }

    const validateRewardRate = () => {
        if (selectStakeType === '' && Number(rewardRate) < 0) {
            alert("Cannot enter numbers less than 0.");
            return false;
        }
        return true;
    }

    // const handleTransferOwnership = (event: any) => {
    //     // @ts-ignore
    //     setTransferOwnership(address);
    // }

    const getAmountTransfer = (event: any) => {
        setAmountTransfer(Number(event.target.value));
    }

    const getAmountRewardRate = (event: any) => {
        setAmountRewardRate(Number(event.target.value));
    }

    const handleSelectStakeType = (event: any) => {
        // @ts-ignore
        setSelectStakeType(Number(event.target.value));
    }



    return (
        <DataContext.Consumer>
            {data => (
                <div className="wrap">
                    <div
                        className={loading ? "pageLoading d-flex align-items-center justify-content-center text-center" : "pageLoading d-flex align-items-center justify-content-center text-center d-none"}
                    >
                        <div>
                            <h5 className="font-orbitronRegular mb-3">Please wait...</h5>
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                    <Header />
                    {/* <!-- Sidebar menu --> */}
                    <div>
                        {/* Sidebar menu */}
                        <Sidebar />

                        {/* Content */}
                        <div id="content">
                            <div className="container dashboard">
                                <div className="row overview">
                                    <div className="row inner bg-white d-flex">
                                        {/* <div className="row inner mb-2 amber">
                                            <div>Flexible STAKING:
                                                {
                                                    // @ts-ignore
                                                    // data.accountAddress ? flexibleStakeInfo : 0
                                                }PHO</div>
                                        </div> */}
                                        <div className="row mb-2">
                                            <div className="col-md-6 item">
                                                <div className="inner amber">
                                                    <div className="row">
                                                        <h5>Set Reward Rate</h5>
                                                    </div>
                                                    <div className="form-group row mb-1">
                                                        <label htmlFor="inputPassword3" className="col-4 col-form-label">Stake Type</label>
                                                        <div className="col-8 ml-2">
                                                            <select defaultValue={'DEFAULT'} id="stakeValue" onChange={(e) => handleSelectStakeType(e)} className="form-select form-control" aria-label="Default select example">
                                                                <option selected value="DEFAULT">Choose token lock type</option>
                                                                <option value="0">1 Month Stake</option>
                                                                <option value="1">3 Month Stake</option>
                                                                <option value="2">Flexible Stake</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label htmlFor="inputPassword3" className="col-4 col-form-label">Reward Rate (%)</label>
                                                        <div className="col-8">
                                                            <input type="number" className="form-control" min={0} onChange={(e) => getAmountRewardRate(e)} placeholder="Enter Reward Rate..." />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-end mt-2">
                                                        <button type="submit" className="btn btn-primary"
                                                            onClick={async () => {
                                                                // @ts-ignore
                                                                if (data.accountAddress) {
                                                                    // @ts-ignore
                                                                    await SetRewardRate(data.accountAddress, data);
                                                                } else {
                                                                    // @ts-ignore
                                                                    data.onConnect()
                                                                }
                                                            }}
                                                        >Set Reward</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 item">
                                                <div className="inner amber">
                                                    <div className="form-group">
                                                        <h5>ForceUnstake</h5>
                                                        <input type="text" className="form-control" aria-describedby="emailHelp" placeholder="Enter address..." />
                                                        {/* <small className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                                    </div>
                                                    <div className="d-flex justify-content-start mt-2">
                                                        <div>
                                                            <button type="button" className="btn btn-primary"
                                                                onClick={async () => {
                                                                    // @ts-ignore
                                                                    if (data.accountAddress) {
                                                                        // @ts-ignore
                                                                        await getForceUnstake(data.accountAddress, data)
                                                                    } else {
                                                                        // @ts-ignore
                                                                        data.onConnect()
                                                                    }
                                                                }}
                                                            >ForceUnstake</button>
                                                        </div>
                                                        <div className="mx-2">
                                                            <button type="button" className="btn btn-primary"
                                                                onClick={async () => {
                                                                    // @ts-ignore
                                                                    if (data.accountAddress) {
                                                                        // @ts-ignore
                                                                        await getforceAllUnstake(data.accountAddress, data)
                                                                    } else {
                                                                        // @ts-ignore
                                                                        data.onConnect()
                                                                    }
                                                                }}
                                                            >ForceAllUnstake</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-md-6 item">
                                                <div className="inner amber">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h5>Transfer</h5>
                                                        </div>
                                                        <div className="col-6 d-flex justify-content-end align-items-center mb-2 fw-bold">
                                                            <div>Balance:
                                                                {
                                                                    // @ts-ignore
                                                                    data.phoTokenAmount
                                                                }  PHO</div>
                                                        </div>

                                                    </div>

                                                    <div className="form-group row">
                                                        <label htmlFor="inputPassword3" className="col-4 col-form-label">Amount</label>
                                                        <div className="col-8">
                                                            <input type="number" className="form-control" min={0} onChange={(e) => getAmountTransfer(e)} placeholder="Enter amount..." />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-end mt-2">
                                                        <button type="submit" className="btn btn-primary"
                                                            onClick={async () => {
                                                                // @ts-ignore
                                                                if (data.accountAddress) {
                                                                    // @ts-ignore
                                                                    await onSubmitAmountTransfer(Number(data.phoTokenAmount), data)
                                                                    // alert("tranfer123: " + transferAddress + " //SL: " + amountTransfer)

                                                                } else {
                                                                    // @ts-ignore
                                                                    data.onConnect()
                                                                }
                                                            }}

                                                        >Deposit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            )}
        </DataContext.Consumer>
    );
}

export default Admin;
